export const state = () => ({
  selectedPITDatas: [],
})

export const getters = {
  selectedPITDatas: (state) => state.selectedPITDatas,
}

export const mutations = {
  SET_SELECTED_PIT_DATAS(state, selectedPITDatas) {
    state.selectedPITDatas = selectedPITDatas
  },
  ADD_SELECTED_PIT_DATA(state, selectedPITData) {
    state.selectedPITDatas.push(selectedPITData)
  },
  CLEAR_SELECTED_PIT_DATAS(state) {
    state.selectedPITDatas = [];
  }
}

export const actions = {
  setSelectedPITDatas({ commit }, payload) {
    commit('SET_SELECTED_PIT_DATAS', payload)
  },
  addSelectedPITDatas({ commit }, payload) {
    commit('ADD_SELECTED_PIT_DATA', payload)
  },
  clearSelectedPITDatas({ commit }) {
    commit('CLEAR_SELECTED_PIT_DATAS')
  },
}
