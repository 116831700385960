export const state = () => ({
  mappedPITVersionData: {},
})

export const getters = {
  mappedPITVersionData: (state) => state.mappedPITVersionData,
}

export const mutations = {
  SET_PIT_REVISE_TAB(state, data) {
    state.mappedPITVersionData[data['key']] = data['data']
  },
  CLEAR_PIT_REVISE_TAB(state) {
    state.mappedPITVersionData = {};
  }
}

export const actions = {
  setPITReviseTab({ commit }, data) {
    commit('SET_PIT_REVISE_TAB', data)
  },
  clearPITReviseTab({ commit }) {
    commit('CLEAR_PIT_REVISE_TAB')
  },
}
