// state
export const state = () => ({
  socialInsuranceAgenciesData: [],
})

// getters
export const getters = {
  socialInsuranceAgenciesData: (state) => state.socialInsuranceAgenciesData,
}

// mutations
export const mutations = {
  UPDATE_SOCIAL_INSURANCE_AGENCIES_DATA(state, socialInsuranceAgencies) {
    state.socialInsuranceAgenciesData = socialInsuranceAgencies
  },
}

// actions
export const actions = {
  updateSocialInsuranceAgenciesData({ commit }, payload) {
    commit('UPDATE_SOCIAL_INSURANCE_AGENCIES_DATA', payload)
  },
}
